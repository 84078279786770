// Home.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginFailure, setAccessPermissions } from '../actions/index'; 
import './Home.css';
import image1 from '../Imagenes/Calidad.png';
import image2 from '../Imagenes/Mantenimiento.png';
import image3 from '../Imagenes/Inventario.png';
import image4 from '../Imagenes/Produccion.png';
import logo from '../Imagenes/logo.png';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const permissions = useSelector((state) => state.access.permissions);

  const handleLogout = () => {
    dispatch(loginFailure('User logged out')); 
    navigate('/login');
  };

  const handleCardClick = (path) => {
    navigate(path); 
  };

  useEffect(() => {
    if (user) {
      let perms = [];
      if (user.Id_Access === 1) {
        perms = [
          'viewCalidad',
          'viewMantenimiento',
          'viewInventario',
          'viewProduccion',
          'updateMachines',
          'deleteMachines',
          'insertMachines',
          // Agregar más permisos según sea necesario
        ];
      } else if (user.Id_Access === 2) {
        perms = [
          'viewMantenimiento',
          // Agregar más permisos según sea necesario
        ];
      }
      dispatch(setAccessPermissions(perms));
    }
  }, [user, dispatch]);

  const accessCards = [
    { id: 1, title: 'Calidad', path: '/Calidad', image: image1, description: 'Content of card 1.', permission: 'viewCalidad' },
    { id: 2, title: 'Mantenimiento', path: '/Mantenimiento', image: image2, description: 'Content of card 1.', permission: 'viewMantenimiento' },
    { id: 3, title: 'Inventario', path: '/Inventario', image: image3, description: 'Content of card 1.', permission: 'viewInventario' },
    { id: 4, title: 'Producción', path: '/Produción', image: image4, description: 'Content of card 1.', permission: 'viewProduccion' },
  ];

  return (
    <div className="home-container">
      <div>
        <img src={logo} alt="Logo CalManInvPro" style={{ background: `rgba(150, 150, 150, 0.53)`, width: `80px`, height: `30px` }} />
        <h1>CalManInvPro</h1>
      </div>
      <p>Bienvenido, {user.Name_User}</p>

      {accessCards.map((card) =>
        permissions.includes(card.permission) && (
          <div key={card.id} className="card" style={{ backgroundImage: `url(${card.image})` }} onClick={() => handleCardClick(card.path)}>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        )
      )}

      {/* Contenedor para el botón */}
      <div className="button-container">
        <button className="homeButton" onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
}

export default Home;
