import { combineReducers } from 'redux';
import { LOGIN_SUCCESS, LOGIN_FAILURE } from '../actions';
import { FETCH_MACHINES_REQUEST, FETCH_MACHINES_SUCCESS, FETCH_MACHINES_FAILURE } from '../actions';
import { SET_ACCESS_PERMISSIONS } from '../actions';

const initialStateUser = {
  user: null,
  error: null,
  isAuthenticated: false,
};

const authReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateMachine = {
  loading: false,
  machines: [],
  error: '',
};

const machineReducer = (state = initialStateMachine, action) => {
  switch (action.type) {
    case FETCH_MACHINES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MACHINES_SUCCESS:
      return {
        loading: false,
        machines: action.payload,
        error: '',
      };
    case FETCH_MACHINES_FAILURE:
      return {
        loading: false,
        machines: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateAccess = {
  permissions: [],
};

const accessReducer = (state = initialStateAccess, action) => {
  switch (action.type) {
    case SET_ACCESS_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

// Combinar los reducers
const rootReducer = combineReducers({
  auth: authReducer,
  machine: machineReducer,
  access: accessReducer,
});

export default rootReducer;
