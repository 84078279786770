// Mantenimiento.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Table from './Table';
import Form from './Form';
import logo from '../Imagenes/logo.png';
import { fetchMachines, fetchMachinesSuccess, fetchMachinesFailure } from '../actions'; // Importa desde ../actions
import './Mantenimiento.css';

const Mantenimiento = () => {
  const [newMachine, setNewMachine] = useState({
    id: '',
    ref: '',
    location: '',
    name: '',
    internal_code: '',
    state: '',
    inst_date: '',
    observation: '',
    company_id: '',
    priority: '',
    imagen: null,
    pdf: null
  });


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const machines = useSelector((state) => state.machine.machines);
  const loading = useSelector((state) => state.machine.loading);
  const error = useSelector((state) => state.machine.error);
  const permissions = useSelector((state) => state.access.permissions);

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchMachinesData = async () => {
      try {
        let data = qs.stringify({
          'action': 'getMachines',
          'id': user.Id_User
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://www.rendacolombia.com/api/',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        };

        const response = await axios.request(config);
        if (response.data && Array.isArray(response.data)) {
          dispatch(fetchMachinesSuccess(response.data));
        } else {
          dispatch(fetchMachinesFailure("Error: No hay maquinas para esta empresa u ocurrio un error al consultar"));
        }
      } catch (error) {
        dispatch(fetchMachinesFailure(error.message));
      }
    };

    if (user && user.Id_User) {
      dispatch(fetchMachines());
      fetchMachinesData();
    }
  }, [dispatch, user]);

  const handleRowClick = (id) => {
    navigate(`/machines/${id}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMachine(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewMachine(prevState => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('action', 'insertMachine');
    data.append('id', user.Id_User);
    data.append('ref', newMachine.ref);
    data.append('location', newMachine.location);
    data.append('name', newMachine.name);
    data.append('internal_code', newMachine.internal_code);
    data.append('state', newMachine.state);
    data.append('inst_date', newMachine.inst_date);
    data.append('observation', newMachine.observation);
    data.append('company_id', user.Id_Empresa);
    data.append('priority', newMachine.priority);
    data.append('imagen', newMachine.imagen);
    data.append('pdf', newMachine.pdf);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://www.rendacolombia.com/api/',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      alert(JSON.stringify(response.data));
    } catch (error) {
      alert(error.message);
    }
    navigate(-1);
  };

  const tableColumns = [
    { header: 'Fecha Instalación', field: 'Inst_Date_Machine' },
    { header: 'Código Interno', field: 'Internall_Code_Machine' },
    { header: 'Ubicación', field: 'Location_Machine' },
    { header: 'Nombre', field: 'Name_Machine' },
    { header: 'Observación', field: 'Observation_Machine' },
    { header: 'Prioridad', field: 'Priority_Machine' },
    { header: 'Referencia', field: 'Ref_Machine' },
    { header: 'Estado', field: 'State_Machine' },
  ];

  const formFields = [
    { label: 'Referencia', name: 'ref', type: 'text' },
    { label: 'Ubicación', name: 'location', type: 'text' },
    { label: 'Nombre', name: 'name', type: 'text' },
    { label: 'Código Interno', name: 'internal_code', type: 'text' },
    { label: 'Estado', name: 'state', type: 'text' },
    { label: 'Fecha de Instalación', name: 'inst_date', type: 'date' },
    { label: 'Observación', name: 'observation', type: 'text' },
    { label: 'Prioridad', name: 'priority', type: 'number' },
    { label: 'Imagen', name: 'imagen', type: 'file' },
    { label: 'PDF', name: 'pdf', type: 'file' },
  ];

  return (
    <div>
      <div className="mantenimiento-main">
        <div className="mantenimiento-image">
          <img src={logo} alt="Logo CalManInvPro" style={{ background: `rgba(150, 150, 150, 0.53)`, width: `80px`, height: `30px` }} />
        </div>
        <Header
          title="Mantenimiento"
          description="El mantenimiento industrial es fundamental para garantizar la operatividad y eficiencia de los equipos y maquinaria en las industrias."
          className="mantenimiento-header"
        />
        <div className="mantenimiento-table">
          <Table
            title="Maquinaria"
            data={machines}
            columns={tableColumns}
            onRowClick={handleRowClick}
            loading={loading}
            error={error}
            className="machines-table"
          />
        </div>
      </div>
      {permissions.includes('insertMachines') && (
        <div className='insert-container'>
          <Form
            title='Insertar Nueva Máquina'
            fields={formFields}
            values={newMachine}
            handleChange={handleInputChange}
            handleFileChange={handleFileChange}
            handleSubmit={handleSubmit}
            submitLabel="Insertar Máquina"
            className="insert-machine-form"
          />
        </div>
      )}
      <div className='center-button-container'>
        <button className="button" onClick={handleBackClick}>Regresar</button>
      </div>
    </div>
  );
};

export default Mantenimiento;
