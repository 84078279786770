// Header.js
import React from 'react';

const Header = ({ title, description, className }) => (
  <div className={className}>
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

export default Header;
