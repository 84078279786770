// src/components/Login.js
import React from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import qs from 'qs';
import { loginSuccess, loginFailure } from '../actions/index';
import './Login.css';
import logo from '../Imagenes/logo.png'

const Login = () => {
  const dispatch = useDispatch();

  const handleLogin = async (event) => {
    event.preventDefault();
    const form = event.target;
    const email = form.email.value;
    const password = form.password.value;

    try {
      let data = qs.stringify({
        'action': 'validateUser',
        'identifier': email,
        'password': password
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.rendacolombia.com/api/',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };

      const response = await axios.request(config);
      
      if (response.data && response.data.Id_User) {
        dispatch(loginSuccess(response.data));
      } else {
        dispatch(loginFailure(response.data.message));
      }
    } catch (error) {
      console.error(error);
      dispatch(loginFailure(error.message));
    }
  };

  return (
    <div>
      <h1>¡Bienvenido a CalManInvPro!</h1>
      <div className="login-container">
        <div className="login-box">
          <img src={logo} alt="Logo CalManInvPro" style={{ background: `rgba(150, 150, 150, 0.53)`, width: `80px`, height: `30px` }} />
          <h2>Iniciar Sesión</h2>
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label>Email:</label>
              <input type="email" name="email" required />
            </div>
            <div className="input-group">
              <label>Contraseña:</label>
              <input type="password" name="password" required />
            </div>
            <button className="button" type="submit">Ingresar</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
