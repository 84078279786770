// src/actions/index.js
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FETCH_MACHINES_REQUEST = 'FETCH_MACHINES_REQUEST';
export const FETCH_MACHINES_SUCCESS = 'FETCH_MACHINES_SUCCESS';
export const FETCH_MACHINES_FAILURE = 'FETCH_MACHINES_FAILURE';
export const SET_ACCESS_PERMISSIONS = 'SET_ACCESS_PERMISSIONS';

export const setAccessPermissions = (permissions) => ({
  type: SET_ACCESS_PERMISSIONS,
  payload: permissions,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error
});

export const fetchMachines = () => ({
  type: FETCH_MACHINES_REQUEST,
});

export const fetchMachinesSuccess = (machines) => ({
  type: FETCH_MACHINES_SUCCESS,
  payload: machines,
});

export const fetchMachinesFailure = (error) => ({
  type: FETCH_MACHINES_FAILURE,
  payload: error,
});
