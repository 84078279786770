import React, { useState } from 'react';
import TableRow from './TableRow';
import * as XLSX from 'xlsx';

const Table = ({ title, data, columns, onRowClick, loading, error, className }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const [filterColumn, setFilterColumn] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleFilterColumnChange = (e) => {
    setFilterColumn(e.target.value);
  };

  const handleFilterValueChange = (e) => {
    setFilterValue(e.target.value);
    setCurrentPage(1); // Reiniciar a la primera página al filtrar
  };

  // Filtrar datos basado en los filtros
  const filteredData = data.filter((item) => {
    if (filterColumn && filterValue) {
      return item[filterColumn].toString().toLowerCase().includes(filterValue.toLowerCase());
    }
    return true;
  });

  // Calcular los elementos a mostrar en la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Manejar la descarga del archivo Excel
  const handleDownloadExcel = () => {
    const filteredColumns = columns.map((col) => col.field);
    const filteredDataForExport = filteredData.map((item) => {
      const newItem = {};
      filteredColumns.forEach((col) => {
        newItem[col] = item[col];
      });
      return newItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExport); // Convierte los datos filtrados a una hoja de cálculo
    const workbook = XLSX.utils.book_new(); // Crea un nuevo libro de trabajo
    XLSX.utils.book_append_sheet(workbook, worksheet, title); // Agrega la hoja de cálculo al libro de trabajo
    XLSX.writeFile(workbook, `${title}.xlsx`); // Guarda el libro de trabajo como un archivo .xlsx
  };

  return (
    <div>
      {loading && <p>Cargando...</p>}
      {error && <p>{error}</p>}
      <div className="table-filters">
        <select value={filterColumn} onChange={handleFilterColumnChange} className="filter-select">
          <option value="">Selecciona una columna</option>
          {columns.map((col) => (
            <option key={col.field} value={col.field}>
              {col.header}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Valor del filtro"
          value={filterValue}
          onChange={handleFilterValueChange}
          className="filter-input"
        />
      </div>
      <h2>{title}</h2>
      <div className={className}>
        <table>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <TableRow key={index} item={item} columns={columns} onRowClick={onRowClick} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-buttons">
        <button onClick={handlePrevPage} disabled={currentPage === 1} className="button">
          Anterior
        </button>
        <button onClick={handleNextPage} disabled={indexOfLastItem >= filteredData.length} className="button">
          Siguiente
        </button>
      </div>
      <div className="download-button-container">
        {filteredData.length > 0 && (
          <button onClick={handleDownloadExcel} className="button">
            Descargar Excel
          </button>
        )}
      </div>
    </div>
  );
};

export default Table;
