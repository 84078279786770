// Form.js
import React from 'react';

const Form = ({title, fields, values, handleChange, handleFileChange, handleSubmit, submitLabel, className }) => (
  <div>
    <h2>{title}</h2>
    <form onSubmit={handleSubmit} className={className}>
      {fields.map((field, index) => (
        <label key={index}>
          {field.label}:
          {field.type === 'file' ? (
            <input type={field.type} name={field.name} onChange={handleFileChange} required />
          ) : (
            <input type={field.type} name={field.name} value={values[field.name]} onChange={handleChange} required />
          )}
        </label>
      ))}
      <div className='center-button-container'>
        <button className= 'button' type="submit">{submitLabel}</button>
      </div>
    </form>
  </div>
);

export default Form;
