// TableRow.js
import React from 'react';

const TableRow = ({ item, columns, onRowClick }) => (
  <tr onClick={() => onRowClick(item.Id_Machine)}>
    {columns.map((col, index) => (
      <td key={index}>{item[col.field]}</td>
    ))}
  </tr>
);

export default TableRow;
