import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import './MachineDetails.css'; // Importa el archivo CSS
import { fetchMachines } from '../actions'; // Importa desde ../actions

const MachineDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine.machines.find(m => m.Id_Machine.toString() === id));
  const user = useSelector((state) => state.auth.user);
  const permissions = useSelector((state) => state.access.permissions);

  const [editingField, setEditingField] = useState(null);
  const [machineData, setMachineData] = useState({
    ref: '',
    location: '',
    name: '',
    internal_code: '',
    state: '',
    inst_date: '',
    observation: '',
    priority: '',
  });

  useEffect(() => {
    if (machine) {
      setMachineData({
        ref: machine.Ref_Machine,
        location: machine.Location_Machine,
        name: machine.Name_Machine,
        internal_code: machine.Internall_Code_Machine,
        state: machine.State_Machine,
        inst_date: machine.Inst_Date_Machine,
        observation: machine.Observation_Machine,
        priority: machine.Priority_Machine,
      });
    }
  }, [machine]);

  if (!machine) {
    return <div>Cargando...</div>;
  }

  const imageUrl = `https://www.rendacolombia.com/api/${machine.Path_Photo_Machine}`;

  const handleBackClick = () => {
    navigate(-1); // Regresa a la página anterior
  };

  const handleDeleteClick = async () => {
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta máquina?");
    if (confirmDelete) {
      let data = qs.stringify({
        'action': 'deleteMachine',
        'id': user.Id_User,
        'id_machine': machine.Id_Machine
      });
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.rendacolombia.com/api/',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        alert(JSON.stringify(response.data));
        dispatch(fetchMachines());
        navigate(-1); // Regresa a la página anterior después de borrar
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMachineData({
      ...machineData,
      [name]: value,
    });
  };

  const handleUpdateClick = async (e) => {
    e.preventDefault();

    let data = qs.stringify({
      'action': 'updateMachine',
      'id': user.Id_User,
      'id_machine': machine.Id_Machine,
      ...machineData,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://www.rendacolombia.com/api/',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      alert(JSON.stringify(response.data));
      dispatch(fetchMachines());
      navigate(-1);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDoubleClick = (field) => {
    if (permissions.includes('updateMachines')) {
      setEditingField(field);
    }
  };

  const handleBlur = () => {
    setEditingField(null);
  };

  return (
    <div className="machine-details-container">
      <div className="machine-details-card">
        {permissions.includes('deleteMachines') && (
          <button className="delete-button" onClick={handleDeleteClick}>Eliminar Máquina</button>
        )}
        <img src={imageUrl} alt="Foto de la máquina" className="machine-image" />
        <div className="machine-details">
          <h2>Detalles de la Máquina</h2>
          <ul>
            <li onDoubleClick={() => handleDoubleClick('internal_code')}>
              <strong>Código Interno:</strong>
              {editingField === 'internal_code' ? (
                <input
                  type="text"
                  name="internal_code"
                  value={machineData.internal_code}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.internal_code
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('ref')}>
              <strong>Referencia:</strong>
              {editingField === 'ref' ? (
                <input
                  type="text"
                  name="ref"
                  value={machineData.ref}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.ref
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('name')}>
              <strong>Nombre:</strong>
              {editingField === 'name' ? (
                <input
                  type="text"
                  name="name"
                  value={machineData.name}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.name
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('location')}>
              <strong>Ubicación:</strong>
              {editingField === 'location' ? (
                <input
                  type="text"
                  name="location"
                  value={machineData.location}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.location
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('inst_date')}>
              <strong>Fecha Instalación:</strong>
              {editingField === 'inst_date' ? (
                <input
                  type="date"
                  name="inst_date"
                  value={machineData.inst_date}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.inst_date
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('state')}>
              <strong>Estado:</strong>
              {editingField === 'state' ? (
                <input
                  type="text"
                  name="state"
                  value={machineData.state}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.state
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('priority')}>
              <strong>Prioridad:</strong>
              {editingField === 'priority' ? (
                <input
                  type="number"
                  name="priority"
                  value={machineData.priority}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.priority
              )}
            </li>
            <li onDoubleClick={() => handleDoubleClick('observation')}>
              <strong>Observación:</strong>
              {editingField === 'observation' ? (
                <input
                  type="text"
                  name="observation"
                  value={machineData.observation}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                machineData.observation
              )}
            </li>
            <li><strong>Manual:</strong> <a href={`https://www.rendacolombia.com/api/${machine.Path_Manual_Machine}`} target="_blank" rel="noopener noreferrer">Manual</a></li>
          </ul>
        </div>
      </div>
      <div className="button-container">
        <button className="back-button" onClick={handleBackClick}>Regresar</button>
        {permissions.includes('updateMachines') && (
          <button className="update-button" onClick={handleUpdateClick}>Actualizar Máquina</button>
        )}
      </div>
    </div>
  );
};

export default MachineDetails;
